<template>
	<main>
		<Header v-if="header" />
		<HeaderMobile v-else />
		<router-view />
	</main>
</template>

<script>
import Header from '@ebradi/componets/Header.vue'
import HeaderMobile from '@ebradi/componets/HeaderMobile.vue'

import '@ebradi/assets/scss/base.scss'

export default {
	name: 'LayoutDefaultEBRADI',

	components: {
		Header,
		HeaderMobile
	},


	computed: {
		header() {
			return this.$route.meta.header;
		},
	},

	mounted() {
		this.$gtag.optIn()
		this.$gtag.pageview(this.$route)

		let cssPortal = document.getElementById('style-portal-ebradi')

		if (cssPortal) {
			cssPortal.remove()
		}
	}
}
</script>
